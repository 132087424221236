.date-time-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .date-time-item {
    padding: 10px;
    margin: 10px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background-color: #692cba;
  }